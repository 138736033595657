import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import map from 'lodash/map';
import Helper from '@helpers/Helper';
import { Row, Col, Card, CardBody, CardHeader, FormGroup, CardFooter, Button, Tooltip } from 'reactstrap';
import IconDownload from '@assets/icons/download.svg';
import API from '@utils/API';
import userPermission from '@utils/userPermission';
import Info from '@assets/icons/infoCircleFill.svg';
import RejectedIcon from '@assets/icons/xSquareFill.svg';
import ApprovedIcon from '@assets/icons/checkSquareFill.svg';
import QuestionIcon from '@assets/icons/questionSquareFill.svg';
import CustomModal from '@components/modal/CustomModal';

function BookingDetailService(props) {
  const { detailData, detailService, title, id, onRevision, status } = props;
  const { generatePermission } = userPermission();
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [infoTooltip, setInfoTooltip] = useState(false);
  const [hasAlert, setHasAlert] = useState(false);

  const openEstimationPage = () => {
    if (detailData?.booking_origin == 'fleet') {
      history.push(`/bookings/${id}/estimation`);
    } else {
      setHasAlert(true);
    }
  };

  async function getEstimatedPrice() {
    setLoading(true);

    const api = new API('v2');
    const response = await api.get(`v2/fleet/bookings/${id}/generate-spk/`, { responseType: 'blob' });

    var file = new Blob([response.data], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);

    setLoading(false);
  }

  return (
    <React.Fragment>
      <CustomModal modal={hasAlert} toggle={() => setHasAlert(false)}>
        <div className="text-center">
          <p className="">Silahkan Approval Via Otobuddy</p>
          <Button
            color="primary"
            className="m-auto px-4 rounded-pill"
            size="lg"
            onClick={() => {
              setHasAlert(false);
            }}
          >
            Ok Mengerti
          </Button>
        </div>
      </CustomModal>
      <Card>
        <Tooltip isOpen={infoTooltip} target="infoTooltipDetail" toggle={() => setInfoTooltip(!infoTooltip)}>
          <p>Rincian pengerjaan servis beserta estimasi harga yang dibuat oleh tim Otoklix.</p>
        </Tooltip>
        <CardHeader className="bg-primary text-white mt-0">
          <div className="ml-3">
            <img className="icon-quest" id="infoTooltipDetail" src={Info} width="15" />
            {title}
          </div>
        </CardHeader>
        <CardBody>
          {detailService?.products?.length > 0 && (
            <FormGroup row className="mb-2">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={6} sm={6} className="col-summary">
                    <span className="text-bold">Produk</span>
                  </Col>
                  <Col lg={2} sm={2} className="col-summary">
                    <span className="text-bold text-right">Diskon</span>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} sm={12}>
                {map(detailService?.products, (detail) => {
                  return (
                    <>
                      <Row>
                        {(status?.slug == 'waiting' || status?.slug == 'complain' || status?.slug == 'cancel') && (
                          <Col lg={1} sm={1} className="col-summary">
                            <span className="detail-group-item-name mt-2 mb-0 ml-1">
                              {detail?.is_approve !== null ? (
                                detail?.is_approve == true ? (
                                  <img src={ApprovedIcon} width="15" />
                                ) : (
                                  <img src={RejectedIcon} width="15" />
                                )
                              ) : (
                                <img src={QuestionIcon} width="15" />
                              )}
                            </span>
                          </Col>
                        )}
                        <Col
                          lg={
                            status?.slug == 'waiting' || status?.slug == 'complain' || status?.slug == 'cancel' ? 5 : 6
                          }
                          sm={
                            status?.slug == 'waiting' || status?.slug == 'complain' || status?.slug == 'cancel' ? 5 : 6
                          }
                          className="col-summary"
                        >
                          <>
                            <span className="detail-group-item-name mt-2 mb-0">{detail?.name}</span>
                            <span className="detail-group-item-qty font-10 text-grey mb-1">{`${
                              detail?.qty
                            } x IDR  ${Helper.formatMoney(detail?.price ?? 0)}`}</span>
                            {detail?.remark && (
                              <>
                                <span className="remarks-item mb-0 font-10 text-grey">{'Keterangan :'}</span>
                                <span className="remarks-item mt-0 font-10 text-grey">
                                  {!detail?.remark ? '-' : detail?.remark?.replace(/-/g, '‑')}
                                </span>
                              </>
                            )}
                          </>
                        </Col>
                        <Col lg={2} sm={2} className="col-summary">
                          <span className="text-right detail-group-item-name discount-otopoints mt-2 mb-0">
                            {Helper.formatMoney(detail?.discount_percentage ?? 0) + '%'}
                          </span>
                        </Col>
                        <Col lg={4} sm={4} className="col-summary">
                          <span className="text-right detail-group-item-name mt-2 mb-0">
                            {'IDR ' + Helper.formatMoney(detail?.price ? detail?.price * detail?.qty : 0)}
                          </span>
                          <span className="text-right detail-group-item-name discount-otopoints mb-0">
                            {'- IDR ' + Helper.formatMoney(detail?.discount_amount ?? 0)}
                          </span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>
            </FormGroup>
          )}

          {detailService?.services?.length > 0 && (
            <FormGroup row className="mb-2">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={6} sm={6} className="col-summary">
                    <span className="text-bold">Produk</span>
                  </Col>
                  <Col lg={2} sm={2} className="col-summary">
                    <span className="text-bold text-right">Diskon</span>
                  </Col>
                </Row>
              </Col>

              <Col lg={12} sm={12}>
                {map(detailService?.services, (detail) => {
                  return (
                    <>
                      <Row>
                        {(status?.slug == 'waiting' || status?.slug == 'complain' || status?.slug == 'cancel') && (
                          <Col lg={1} sm={1} className="col-summary">
                            <span className="detail-group-item-name mt-2 mb-0 ml-1">
                              {detail?.is_approve !== null ? (
                                detail?.is_approve == true ? (
                                  <img src={ApprovedIcon} width="15" />
                                ) : (
                                  <img src={RejectedIcon} width="15" />
                                )
                              ) : (
                                <img src={QuestionIcon} width="15" />
                              )}
                            </span>
                          </Col>
                        )}
                        <Col
                          lg={
                            status?.slug == 'waiting' || status?.slug == 'complain' || status?.slug == 'cancel' ? 5 : 6
                          }
                          sm={
                            status?.slug == 'waiting' || status?.slug == 'complain' || status?.slug == 'cancel' ? 5 : 6
                          }
                          className="col-summary"
                        >
                          <>
                            <span className="detail-group-item-name mt-2 mb-0">{detail?.name}</span>
                            <span className="detail-group-item-qty font-10 text-grey mb-1">{`${
                              detail?.qty
                            } x IDR  ${Helper.formatMoney(detail?.price ?? 0)}`}</span>
                            {detail?.remark && (
                              <>
                                <span className="remarks-item mb-0 font-10 text-grey">{'Keterangan :'}</span>
                                <span className="remarks-item mt-0 font-10 text-grey">
                                  {!detail?.remark ? '-' : detail?.remark?.replace(/-/g, '‑')}
                                </span>
                              </>
                            )}
                          </>
                        </Col>
                        <Col lg={2} sm={2} className="col-summary">
                          <span className="text-right detail-group-item-name discount-otopoints mt-2 mb-0">
                            {Helper.formatMoney(detail?.discount_percentage ?? 0) + '%'}
                          </span>
                        </Col>
                        <Col lg={4} sm={4} className="col-summary">
                          <span className="text-right detail-group-item-name mt-2 mb-0">
                            {'IDR ' + Helper.formatMoney(detail?.price ? detail?.price * detail?.qty : 0)}
                          </span>
                          <span className="text-right detail-group-item-name discount-otopoints mb-0">
                            {'- IDR ' + Helper.formatMoney(detail?.discount_amount ?? 0)}
                          </span>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>
            </FormGroup>
          )}
          {detailService?.packages?.length > 0 && (
            <FormGroup row className="mb-2">
              <Col lg={12} sm={12}>
                <Row>
                  <Col lg={6} sm={6} className="col-summary">
                    <span className="text-bold">Paket</span>
                  </Col>
                  <Col lg={2} sm={2} className="col-summary">
                    <span className="text-bold text-right">Diskon</span>
                  </Col>
                </Row>
              </Col>

              {map(detailService?.packages, (item) => {
                return (
                  <>
                    <Col lg={12} sm={12} className="mt-2">
                      <Row>
                        {(status?.slug == 'waiting' || status?.slug == 'complain' || status?.slug == 'cancel') && (
                          <Col lg={1} sm={1} className="col-summary">
                            <span className="detail-group-item-name mt- mb-0 ml-1">
                              {item?.is_approve !== null ? (
                                item?.is_approve == true ? (
                                  <img src={ApprovedIcon} width="15" />
                                ) : (
                                  <img src={RejectedIcon} width="15" />
                                )
                              ) : (
                                <img src={QuestionIcon} width="15" />
                              )}
                            </span>
                          </Col>
                        )}
                        <Col
                          lg={
                            status?.slug == 'waiting' || status?.slug == 'complain' || status?.slug == 'cancel' ? 5 : 6
                          }
                          sm={
                            status?.slug == 'waiting' || status?.slug == 'complain' || status?.slug == 'cancel' ? 5 : 6
                          }
                          className="col-summary"
                        >
                          <span className="text-bold">{item?.name ?? '-'}</span>
                        </Col>
                        <Col lg={2} sm={12} className="col-summary">
                          <span className="detail-group discount-otopoints text-right">{`${0}%`}</span>
                        </Col>
                        <Col lg={4} sm={6} className="col-summary">
                          <span className="text-right text-bold">{`IDR ${Helper.formatMoney(item?.price ?? 0)}`}</span>
                        </Col>
                      </Row>
                    </Col>
                    {map(item?.item_details, (subitem, index) => {
                      return (
                        <Col lg={12} sm={12}>
                          <Row>
                            {(status?.slug == 'waiting' || status?.slug == 'complain' || status?.slug == 'cancel') && (
                              <Col lg={1} sm={1}></Col>
                            )}
                            <Col
                              lg={
                                status?.slug == 'waiting' || status?.slug == 'complain' || status?.slug == 'cancel'
                                  ? 5
                                  : 6
                              }
                              sm={
                                status?.slug == 'waiting' || status?.slug == 'complain' || status?.slug == 'cancel'
                                  ? 5
                                  : 6
                              }
                              className="col-summary"
                            >
                              <>
                                <span className="detail-group-item-name">{subitem?.name}</span>
                                <span className="detail-group-item-qty font-10 text-grey mb-1">{subitem?.qty}</span>
                              </>
                            </Col>

                            <Col lg={2} sm={12} className="col-summary">
                              {/* <span className="detail-group discount-otopoints text-right">
                                {`${subitem?.discount_percentage ?? 0}%`}
                              </span> */}
                            </Col>
                            <Col lg={4} sm={12} className="col-summary">
                              {/* <span className="detail-group text-right">
                                {'IDR ' + Helper.formatMoney(subitem?.price ?? 0)}
                              </span>
                              <span className="detail-group text-right discount-otopoints">
                                {'- IDR ' + Helper.formatMoney(subitem?.discount_amount ?? 0)}
                              </span> */}
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                  </>
                );
              })}
            </FormGroup>
          )}
          <FormGroup row className="mb-0">
            <Col lg={12} sm={12} className="col-summary">
              <div className="divider"></div>
            </Col>
            <Col lg={12} sm={12} className="col-summary">
              <Row>
                <Col lg={9} sm={6} className="col-summary">
                  <span className="text-bold mb-3">{'Subtotal'}</span>
                </Col>
                <Col lg={3} sm={6} className="col-summary">
                  <span className="price">{'IDR ' + Helper.formatMoney(detailData?.price_before_tax)}</span>
                </Col>
              </Row>
              <Row>
                <Col lg={9} sm={6} className="col-summary">
                  <span className="text-bold mb-3">{'PPN'}</span>
                </Col>
                <Col lg={3} sm={6} className="col-summary">
                  <span className="price">{'IDR ' + Helper.formatMoney(detailData?.tax)}</span>
                </Col>
              </Row>
              <Row>
                <Col lg={9} sm={6} className="col-summary">
                  <span className="text-bold mb-0">{'PPH'}</span>
                </Col>
                <Col lg={3} sm={6} className="col-summary detail-group-item-name discount-otopoints">
                  <span className="price">{'- IDR ' + Helper.formatMoney(detailData?.service_tax)}</span>
                </Col>
              </Row>
            </Col>
            <Col lg={12} sm={12} className="col-summary">
              <div className="divider"></div>
            </Col>
            <Col lg={12} sm={12} className="col-summary">
              <Row>
                <Col lg={9} sm={6} className="col-summary">
                  <span className="text-bold mb-0">{'Total'}</span>
                </Col>
                <Col lg={3} sm={6} className="col-summary">
                  <span className="price">{'IDR ' + Helper.formatMoney(detailData?.total_price)}</span>
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </CardBody>

        {status?.slug == 'waiting' && (
          <CardFooter className="p-3">
            <Button color="warning" block onClick={() => openEstimationPage()} disabled={onRevision}>
              Review Detail Servis
            </Button>
          </CardFooter>
        )}

        {status?.slug == 'review' && (
          <CardFooter className="p-3">
            <Button
              block
              disabled={true}
              color="grey"
              style={{ backgroundColor: 'lightgrey', color: 'black', text: 'bold' }}
            >
              Menunggu Estimasi
            </Button>
          </CardFooter>
        )}

        {status?.slug == 'complain' && (
          <CardFooter className="p-3">
            <Button
              block
              disabled={true}
              color="grey"
              style={{ backgroundColor: 'lightgrey', color: 'black', text: 'bold' }}
            >
              Menunggu Revisi
            </Button>
          </CardFooter>
        )}
      </Card>
    </React.Fragment>
  );
}

export default BookingDetailService;
